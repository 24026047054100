













































































































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import NowDateTime from '@/components/atoms/NowDateTime.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import PropertiesDesign from '@/mixins/v3/PropertiesDesign'
import { ICON_LIST } from '@/constants'
import NursingBranch from '@/mixins/v3/NursingBranch'
import VictoryBranch from '@/mixins/v3/VictoryBranch'

type ModeType = {
  id?: number
  name?: string
  description?: string
  code: string
  learning_range_type: string
  s3_path_id: string
  is_mode_default: boolean
}

type Links = {
  dashboard: string
  lesson: string // 授業を始める
  homework: string // 宿題
  history: string // 間違い直し
  goal: string // 学習計画
  mistakes: string // 間違い直し
  timeline: string // タイムライ
  assistMaterial: string // 補助教材
  schedule: string //予定表
  victory: string
  challenge: string
}

@Component({
  components: {
    ImageRound,
    LabelBase,
    ColoredBox,
    ButtonBase,
  },
})
export default class StudentSidebar extends Mixins(
  NowDateTime,
  ClassModeChangeAble,
  LoginMethods,
  PropertiesDesign,
  NursingBranch,
  VictoryBranch
) {
  // メニューモード
  private menuMode = ''
  // 都道府県選択状態(入試モードの学習開始URL分けに使用)
  private isSelectedPrefecture = false
  private isShowBtnEntrance = false
  private routerActive = ''
  private studentClass = false
  // リンク先オブジェクト
  private links: Links = {
    dashboard: '/student/dashboard',
    lesson: '/student/subjects/KK',
    homework: '/student/v3/homework/SY',
    history: '/student/v3/history/learning-situation',
    goal: '/student/v3/goal-list',
    assistMaterial: '/student/assist-material/operation',
    mistakes: '/student/v2/subjects/MN',
    timeline: '/student/v3/timeline',
    schedule: '/student/v3/schedule',
    victory: '/student/v3/victory',
    challenge: '/student/v3/challenge'
  }
  private showCalendarAndGradeBook = false

  // private links: Links = {
  //   lesson: '/student/v2/subjects/AI',
  //   homework: '/student/subjects/SY',
  //   history: '/student/v2/history/normal/learning-situation',
  //   goal: '/student/goal-list',
  //   assistMaterial: '/student/assist-material/operation',
  //   mistakes: '/student/v2/subjects/MN',
  //   timeline: '/student/timeline',
  // }
  // リストモード
  private listMode: ModeType[] = []

  private get varible() {
    return `--color-button-primary: ${this.colorButton.primary};
            --color-button-second: ${this.colorButton.second};
            --color-button-active: ${this.colorButton.active};
            --color-button-active-second: ${this.colorButton.activeSecond};
            --color-text: ${this.colorButton.colorText}`
  }

  private get cookie() {
    // クラステスト
    const highShool = ['t1', 't2', 't3']
    const gradeCode = Vue.prototype.$cookies.get('dataGdls').grade.code
    this.studentClass = highShool.includes(gradeCode)
    return Vue.prototype.$cookies.get('dataGdls')
  }

  private getNameIcon(name: string) {
    const NAME_FALLBACK = 'pencil'
    if (!ICON_LIST.some((item) => item.value === name)) return NAME_FALLBACK
    return name
  }

  private loginUser() {
    Vue.prototype.$http.httpWithToken.get('/v3/users/loginUser').then((res: any) => {
      const currentDataUser = Vue.prototype.$cookies.get('dataGdls') || {}

      Vue.prototype.$cookies.set(
        'dataGdls',
        { ...currentDataUser, digestGdlsCode: res?.data?.digestGdlsCode },
        Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)()
      )
      const isOnAir = res.data.isStudentOfOnairAcademy
      if (isOnAir) {
        this.showCalendarAndGradeBook = true
        return
      }
      this.showCalendarAndGradeBook = res.data.showCalendarAndGradeBook
    })
  }
  // 生徒情報
  private get student(): { name: string; icon: string; lessonEnable: boolean } {
    return {
      name: this.cookie.userNickname || '',
      icon: this.cookie.userIconUrl || '',
      lessonEnable: this.cookie.isHomeLearningEnabled || false,
    }
  }

  private loadEntranceSubjects() {
    if (this.hide.isBtnMeasure) {
      this.isShowBtnEntrance = false
      return
    }
    Vue.prototype.$http.httpWithToken.get('/entrance/active_subjects').then((res: any) => {
      this.$store.commit('activeSubjects/setActiveSubjects', res.data)
      this.isShowBtnEntrance = res.data.length > 0
    })
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.startBgWhite()
    if (this.$route.params.classMode) {
      this.routerActive = `modeActive${this.$route.params.classMode}`
    } else {
      this.routerActive = window.location.pathname
    }
    this.loginUser()
    this.loadEntranceSubjects()
    // ログアウト用のモードを設定
    this.mode = this.MODE.STUDENT
    if (!this.hide.isModeCustom) {
      this.loadModes()
    }
    await this.setSideMenuMode().catch(() => {
      // cookieが読み込めない場合はログアウト
      this.logout()
    })

    // 入試モードでは生徒の都道府県選択状態取得
    if (this.isEntranceMode) await this.loadStudentPrefecture()

    // 初期値セット
    this.initProperties()
    Vue.prototype.$loading.complete()
  }

  /**
   * sideMenuModeをCookieにセット
   *
   * classModeの値が基準になるが、
   * 補助教材モード(HJ)の場合sideMenuModeの変更は行わず、
   * 前のclassModeの状態で表示する
   */
  private async setSideMenuMode() {
    const cookie = this.cookie
    if (!cookie.classMode) {
      // classModeがセットされていない場合、ログイン画面からの遷移と判断し、初期値を挿入
      cookie.classMode = this.CLASS_MODE.NORMAL
    } else if (cookie.classMode === this.CLASS_MODE.ASSIST || cookie.classMode === cookie.sideMenuMode) {
      // classModeに'HJ'(補助教材)がセットされている or classModeとsideMenuModeが同じ場合は更新不要
      return
    }

    cookie.sideMenuMode = cookie.classMode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * プロパティの初期化
   *
   * linksをMenuModeに応じて変更
   */
  private initProperties() {
    this.menuMode = this.cookie.sideMenuMode
    switch (this.menuMode) {
      // case this.CLASS_MODE.TEST:
      //   this.links.lesson = '/student/subjects/EN'
      //   this.links.history = '/student/history/test/step-situation'
      //   break
      // case this.CLASS_MODE.SEMINAR:
      //   this.links.lesson = '/student/subjects'
      //   this.links.history = '/student/history/seminar/target-situation'
      //   break
      case this.CLASS_MODE.ENTRANCE:
        this.links.lesson = this.isSelectedPrefecture ? '/student/subjects' : '/student/prefectures'
        break
    }
  }

  /**
   * 画面遷移処理
   *
   * 補助教材画面へ遷移する際、classModeに'HJ'をセットする
   * classModeが'HJ'の状態で、その他のメニューを選択した場合、classModeを元の状態に戻す
   * タイムライン画面へ遷移する際、検索条件クッキーを削除する
   */
  private async transition(url: string, key: string) {
    Vue.prototype.$logger.info(`-- keykey ${key}`)
    this.routerActive = url
    const cookie = this.cookie
    if (url === this.links.assistMaterial && cookie.classMode !== this.CLASS_MODE.ASSIST) {
      await this.changeClassMode(this.CLASS_MODE.ASSIST)
    } else if (cookie.classMode === this.CLASS_MODE.ASSIST) {
      await this.changeClassMode(cookie.sideMenuMode)
    }
    if (url == this.links.timeline) {
      cookie['timelineConditions'] = null
      cookie['reportConditions'] = null
      await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    }


    // if (url == this.links.dashboard) {
    //   url = this.links.dashboard + '/' + key
    // }
    if (this.$route.path != url) this.$router.push(url)
  }

  /**
   * 過去に登録済みの都道府県取得
   */
  private async loadStudentPrefecture() {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/student_prefectures')
    this.isSelectedPrefecture = result.data?.prefectureId ? true : false
  }

  //- set mode NY

  private async setMode(classMode: ModeType) {
    // this.routerActive = url
    this.routerActive = `modeActive${classMode.code}`
    let classModeCode = classMode.code
    if (classMode.code == 'NY') {
      classModeCode = 'NY'
      await this.loadStudentPrefecture()
    } else {
      classModeCode = classMode.code?.toString()
    }
    await this.changeClassMode(classModeCode)
    Vue.prototype.$logger.info(`-- transition ${classModeCode}`)
    this.noticeOrStackCurrentPage({ classModeCode: classModeCode })
    if (classMode.code == 'NY') {
      this.$router.push(this.isSelectedPrefecture ? '/student/subjects/NY' : '/student/prefectures')
    } else if (classMode.code == 'AI') {
      this.$router.push('/student/v2/subjects/AI')
    } else if (classMode.learning_range_type == 'student_setting') {
      window.location.href = `/student/self-setting/${classMode.id}`
    } else {
      window.location.href = `/student/v3/subjects/${classModeCode}`
    }
    this.$store.commit('drillsV3/setModeActive', classMode)
  }
  private async loadModes() {
    const listMode = this.$store.getters['listModeV3/listMode']
    if (listMode.length === 0) {
      const { data: modes } = await Vue.prototype.$http.httpWithToken.get(`/v3/student_class_modes`, {
        params: { is_student: 'TRUE', page: 1, limit: 200 },
      })
      const _modes = modes.class_modes.filter((mode: any) => !mode.is_mode_default)
      this.$store.commit('listModeV3/setListMode', _modes)
    }
    this.listMode = this.$store.getters['listModeV3/listMode']
    const classMode = this.listMode.find((o) => o.code === this.$route.params.classMode)
    this.$store.commit('drillsV3/setModeActive', classMode)
  }
}
